body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* center a div */
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.side-menu-margin {
  margin-left: 80px;
  transition: 0.2s;
}

.side-menu-margin-extended {
  margin-left: 280px;
  transition: 0.2s;
}

.kingsale-primary-button {
  border-radius: 10px !important;
  border: 1px solid #e6bd4f !important;
  color: #e6bd4f !important;
}

.kingsale-primary-button:hover {
  box-shadow: 0 4px 30px rgba(230, 189, 79, 0.4);
}

.mobile-menu {
  margin-top: -3px;
  margin-left: 4px;
}

.kingsale-card-bg {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(255, 255, 255, 0.05) 100%) !important;
  border-radius: 16px !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  border: 1px solid rgba(230, 190, 79, 0.4) !important;
}

.primary-text {
  color: #e6bd4f;
}

/* table header styles */
.ant-table-thead > tr >th{
  background: #755603 !important;
}

.break-text {
  word-wrap: break-word
}

.cd-digit-cell {
  font-size: 20px;
  font-weight: 900;
  background-color: #e6bd4f;
  padding: 5px;
  margin: 0 3px;
  border-radius: 3px;
  color: black;
}

/* table rows styles */
.ant-table-tbody > tr{
  background: #141414 !important;
}

.landing-section {
  margin-top: 150px;
}

.landing-section-image {
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* In process description color */
.ant-steps-item-process .ant-steps-item-description {
  font-size: x-small !important;
}

/* Description color finished */
.ant-steps-item-finish .ant-steps-item-description {
  font-size: x-small !important;
}

/* Discription color waiting */
.ant-steps-item-wait .ant-steps-item-description {
  font-size: x-small !important;
}

@media (max-width: 1000px){
  .side-menu-margin-extended {
    margin: 0;
  }

  .side-menu {
    display: none;
  }

  .hide-on-mobile {
    display: none !important;
  }

  .landing-section-text {
    margin-top: none;
  }

  .landing-section-text {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1000px){
  .mobile-menu {
    display: none;
  }

  .hide-on-pc {
    display: none;
  }
}

.rounded-input {
  border-radius: 5px !important;
}
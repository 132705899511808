.landing-text {
    font-family: 'Rubik', sans-serif;
}

.feature-card {
    border-radius: none !important;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0.05) 100%) !important;
    border-top-right-radius: 30px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(230, 190, 79, 0.4) !important;
}
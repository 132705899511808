.bg-blue {
    background: #15161b;
    z-index: 1 !important; /* this line added to show side menu drawer fully */
}
  
.ant-drawer-body {
  background-color: #15161b;
  color: #fff;
}

.trending-up-button {
  border: none !important;
}

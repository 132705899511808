@media (min-width: 1024px) {
    .presale-padding {
        padding: 15px;
    }
}

.progressbar-text-inside {
    z-index: 1;
    color: white;
    font-size: 8px !important;
    font-weight: bold;
}
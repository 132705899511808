.back-button {
    cursor: pointer;
}

.back-button:hover {
    color: #e6bd4f;
}

.user-profile-pic {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #141414;
    padding: 5px;
    border: 1px solid grey;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    object-fit: cover;
}

.cover-area {
    height: 150px;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background-position: center !important; 
    background-repeat: no-repeat !important;
    background-size: cover !important; 
}

.profile-name {
    font-size: 20px;
    font-weight: 600;
    margin-top: 3px;
    margin-left: 10px;
}

.social-links-private-sale-profile {
    margin-right: 8px;
}

.video-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }
  
  /* Then style the iframe to fit in the container div with full height and width */
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }